import axios from 'axios';
import { getCookie } from '@/utils/getCookie';

const usePublicFetcher = async (route: string, data: unknown) => {
  const jwt = getCookie('jwt');
  const apiLink = process.env.NEXT_PUBLIC_BASE_URL;

  try {
    const response = await axios.post(`${apiLink}/${route}`, data, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
      withCredentials: true,
    });

    return response.data;
  } catch (error) {
    return { error, status: 'error' };
  }
};

export default usePublicFetcher;
