import { Popover, PopoverContent, PopoverTrigger } from '@nextui-org/react';
import Image from 'next/image';
import { useEffect, useState } from 'react';
import { toast } from 'sonner';
import {
  CustomAccordion,
  CustomAccordionContent,
  CustomAccordionTrigger,
} from '@/components/custom/Accordion';
import { FaChildren } from '@/Icons/fa6';
import { FiMinus, FiPlus } from '@/Icons/fi';
import { ImManWoman } from '@/Icons/im';
import { MdChildCare } from '@/Icons/md';
import { TbSquareRoundedCheck, TbSquareRoundedCheckFilled } from '@/Icons/tb';
import { cabinClass } from '@/utils/cabinClass';
import { getAirlines } from '@/utils/getAirlines';

const TravellerFields = ({
  label,
  stateValue,
  stateUpdatePassenger,
  stateUpdateCabin,
  stateUpdateAirlines,
}) => {
  const [isTravelerOpen, setIsTravelerOpen] = useState(false);
  const [isCabinOpen, setIsCabinOpen] = useState(false);

  const [airlinesQuery, setAirlinesQuery] = useState('');
  const [airlinesQueryValue, setAirlinesQueryValue] = useState([]);

  const totalPassenger =
    stateValue?.passenger?.adult +
    stateValue?.passenger?.child +
    stateValue?.passenger?.infant;

  const stateValueAirlines = stateValue?.filter?.airline;

  useEffect(() => {
    const fetchAndUpdateAirlines = async (query: string, setter: any) => {
      if (query.length >= 2) {
        try {
          const response = await getAirlines(query);

          // Filter out items that are already in airlinesQueryValue
          const filteredResponse = response.filter(
            (item: any) =>
              !airlinesQueryValue.some((airline) => airline.code === item.code),
          );

          // Only add new items to the state
          setter((prev: any) => [...prev, ...filteredResponse]);
        } catch (error) {}
      } else {
        setter([]);
      }
    };

    fetchAndUpdateAirlines(airlinesQuery, setAirlinesQueryValue);
  }, [airlinesQuery]);

  const handleRemove = (item: { code: any }, index: number) => {
    const updatedAirlines = [...stateValueAirlines];
    updatedAirlines.splice(index, 1);
    stateUpdateAirlines(updatedAirlines);

    // Add the removed item back to the airlinesQueryValue state
    if (!airlinesQueryValue.some((airline) => airline.code === item.code)) {
      setAirlinesQueryValue((prev) => [...prev, item]);
    }
  };

  return (
    <Popover
      placement="bottom"
      isOpen={isTravelerOpen}
      onOpenChange={setIsTravelerOpen}
      triggerScaleOnOpen={false}
    >
      <PopoverTrigger>
        <div
          aria-label="Traveller Input"
          className={`relative flex h-12 w-full max-w-full cursor-pointer rounded-lg border bg-gray-100 lg:h-[50px] 2xl:bg-white ${
            isTravelerOpen ? 'border-blue-600' : ''
          }`}
        >
          <div className="flex h-full w-11 items-center justify-center overflow-hidden whitespace-nowrap border-r font-semibold lg:w-14 2xl:w-10">
            {stateValue?.passenger?.adult +
              stateValue?.passenger?.child +
              stateValue?.passenger?.infant}
          </div>

          <div className="flex min-w-0 flex-1 flex-col justify-center pl-[10px] pr-3">
            <p className="truncate text-sm font-semibold">
              {totalPassenger} Traveller
            </p>
            <p className="truncate text-xs">
              {stateValue?.filter?.cabinClass?.label}
            </p>
          </div>

          {/* input level */}
          <p
            className={`absolute -top-[7px] left-2 select-none rounded-full border bg-white px-1 text-[8px] uppercase tracking-wide lg:text-[9px] 2xl:border-none 2xl:bg-white ${
              isTravelerOpen ? 'border-blue-600 text-blue-600' : ''
            }`}
          >
            {label}
          </p>
        </div>
      </PopoverTrigger>
      <PopoverContent className="rounded-lg bg-white p-3">
        <div className="w-full space-y-4 md:w-60">
          <div className="space-y-3">
            {/* adult part */}
            <div className="flex items-center gap-2 border-b pb-2">
              <p className="h-full w-6">
                <ImManWoman className="text-lg text-blue-600" />
              </p>
              <div className="w-32">
                <h3 className="text-[13px] font-semibold text-zinc-600">
                  Adults
                </h3>
                <p className="text-[10px] font-medium leading-tight text-zinc-500">
                  12 years & above
                </p>
              </div>
              <div className="flex items-center gap-1">
                {stateValue?.passenger?.adult > 1 ? (
                  <button
                    onClick={() => stateUpdatePassenger(-1, 'adult')}
                    className="flex size-6 items-center justify-center rounded-full border border-blue-500 text-blue-500 transition-colors duration-300 ease-linear hover:bg-blue-100"
                  >
                    <FiMinus className="text-xl" />
                  </button>
                ) : (
                  <p className="flex size-6 items-center justify-center rounded-full border border-zinc-300 text-zinc-400">
                    <FiMinus className="text-xl" />
                  </p>
                )}

                <p className="flex w-5 select-none items-center justify-center font-semibold">
                  {stateValue?.passenger?.adult}
                </p>

                {stateValue?.passenger?.adult +
                  stateValue?.passenger?.child +
                  stateValue?.passenger?.infant <
                  9 && stateValue?.passenger?.adult < 9 ? (
                  <button
                    onClick={() => stateUpdatePassenger(+1, 'adult')}
                    className="flex size-6 items-center justify-center rounded-full border border-blue-500 text-blue-500 transition-colors duration-300 ease-linear hover:bg-blue-100"
                  >
                    <FiPlus className="text-lg" />
                  </button>
                ) : (
                  <p className="flex size-6 items-center justify-center rounded-full border border-zinc-300 text-zinc-400">
                    <FiPlus className="text-lg" />
                  </p>
                )}
              </div>
            </div>

            {/* child part */}
            <div className="flex items-center gap-2 border-b pb-2">
              <p className="h-full w-6">
                <FaChildren className="text-lg text-blue-600" />
              </p>

              <div className="w-32">
                <h3 className="text-[13px] font-semibold text-zinc-600">
                  Child
                </h3>
                <p className="text-[10px] font-medium leading-tight text-zinc-500">
                  From 2 years to 12 years
                </p>
              </div>
              <div className="flex items-center gap-1">
                {stateValue?.passenger?.child > 0 ? (
                  <button
                    onClick={() => stateUpdatePassenger(-1, 'child')}
                    className="flex size-6 items-center justify-center rounded-full border border-blue-500 text-blue-500 transition-colors duration-300 ease-linear hover:bg-blue-100"
                  >
                    <FiMinus className="text-xl" />
                  </button>
                ) : (
                  <p className="flex size-6 items-center justify-center rounded-full border border-zinc-300 text-zinc-400">
                    <FiMinus className="text-xl" />
                  </p>
                )}

                <p className="flex w-5 select-none items-center justify-center font-semibold">
                  {stateValue?.passenger?.child}
                </p>

                {stateValue?.passenger?.adult +
                  stateValue?.passenger?.child +
                  stateValue?.passenger?.infant <
                  9 && stateValue?.passenger?.child < 9 ? (
                  <button
                    onClick={() => stateUpdatePassenger(+1, 'child')}
                    className="flex size-6 items-center justify-center rounded-full border border-blue-500 text-blue-500 transition-colors duration-300 ease-linear hover:bg-blue-100"
                  >
                    <FiPlus className="text-lg" />
                  </button>
                ) : (
                  <p className="flex size-6 items-center justify-center rounded-full border border-zinc-300 text-zinc-400">
                    <FiPlus className="text-lg" />
                  </p>
                )}
              </div>
            </div>

            {/* infant part */}
            <div className="flex items-center gap-2 border-b pb-2">
              <p className="h-full w-6">
                <MdChildCare className="text-lg text-blue-600" />
              </p>

              <div className="w-32">
                <h3 className="text-[13px] font-semibold text-zinc-600">
                  Infants
                </h3>
                <p className="text-[10px] font-medium leading-tight text-zinc-500">
                  Under 2 years
                </p>
              </div>
              <div className="flex items-center gap-1">
                {stateValue?.passenger?.infant > 0 ? (
                  <button
                    onClick={() => stateUpdatePassenger(-1, 'infant')}
                    className="flex size-6 items-center justify-center rounded-full border border-blue-500 text-blue-500 transition-colors duration-300 ease-linear hover:bg-blue-100"
                  >
                    <FiMinus className="text-xl" />
                  </button>
                ) : (
                  <p className="flex size-6 items-center justify-center rounded-full border border-zinc-300 text-zinc-400">
                    <FiMinus className="text-xl" />
                  </p>
                )}

                <p className="flex w-5 select-none items-center justify-center font-semibold">
                  {stateValue?.passenger?.infant}
                </p>

                {stateValue?.passenger?.adult +
                  stateValue?.passenger?.child +
                  stateValue?.passenger?.infant <
                  9 && stateValue?.passenger?.infant < 9 ? (
                  <button
                    onClick={() => stateUpdatePassenger(+1, 'infant')}
                    className="flex size-6 items-center justify-center rounded-full border border-blue-500 text-blue-500 transition-colors duration-300 ease-linear hover:bg-blue-100"
                  >
                    <FiPlus className="text-lg" />
                  </button>
                ) : (
                  <p className="flex size-6 items-center justify-center rounded-full border border-zinc-300 text-zinc-400">
                    <FiPlus className="text-lg" />
                  </p>
                )}
              </div>
            </div>
          </div>

          {/* cabin class part */}
          <CustomAccordion
            isOpen={isCabinOpen}
            setIsOpen={setIsCabinOpen}
            className="rounded-md border"
          >
            <CustomAccordionTrigger className="px-3 py-2 text-[13px] text-zinc-600 lg:text-[13px]">
              {stateValue?.filter?.cabinClass?.label}
            </CustomAccordionTrigger>
            <CustomAccordionContent>
              {cabinClass?.map((data) => (
                <div
                  key={data.id}
                  onClick={() => {
                    stateUpdateCabin(data);
                    setIsCabinOpen(false);
                  }}
                  className={`flex cursor-pointer items-center gap-[6px] px-[10px] py-[6px] text-xs font-medium ${
                    stateValue?.filter?.cabinClass?.label === data.label
                      ? 'text-blue-700 hover:bg-blue-50'
                      : 'text-zinc-600 hover:bg-blue-50'
                  }`}
                >
                  {stateValue?.filter?.cabinClass?.label === data.label ? (
                    <>
                      <TbSquareRoundedCheckFilled className="text-lg" />
                      {data.label}
                    </>
                  ) : (
                    <>
                      <TbSquareRoundedCheck className="text-lg" />
                      {data.label}
                    </>
                  )}
                </div>
              ))}
            </CustomAccordionContent>
          </CustomAccordion>

          <div className="relative w-full">
            <div className="relative flex items-center gap-1 bg-white dark:bg-black">
              <div className="relative w-full bg-white">
                <input
                  type="text"
                  id="prefarredAirlines"
                  placeholder=""
                  // value={airlinesQuery && airlinesQuery}
                  defaultValue={airlinesQuery && airlinesQuery}
                  onChange={(e: any) => setAirlinesQuery(e.target.value)}
                  className="peer w-full rounded-lg border border-gray-300 bg-transparent p-2 text-sm font-medium transition duration-300 ease-in-out placeholder:text-transparent focus:border-transparent focus:outline-none focus:ring-2 focus:ring-blue-500 dark:border-gray-800"
                />
                <label
                  htmlFor="prefarredAirlines"
                  className="absolute -top-2 left-1 mx-1 cursor-text select-none rounded-full bg-inherit px-1 text-xs transition-all peer-placeholder-shown:top-2 peer-placeholder-shown:text-sm peer-placeholder-shown:text-gray-500 peer-focus:-top-2 peer-focus:text-xs peer-focus:tracking-wider peer-focus:text-blue-600 dark:peer-placeholder-shown:text-slate-300"
                >
                  Prefarred Air.
                </label>
              </div>

              <div className="w-full">
                {stateValueAirlines.length > 0 && (
                  <div className="flex max-w-[240px] flex-wrap items-center justify-end gap-1">
                    {stateValueAirlines.map((item: any, i: any) => (
                      <button
                        key={i + Math.random()}
                        className="size-8 select-none rounded-full border border-blue-200 bg-blue-50 text-xs text-blue-500 hover:bg-blue-100"
                        onClick={() => handleRemove(item, i)}
                      >
                        {item.code}
                      </button>
                    ))}
                  </div>
                )}
              </div>
            </div>

            {airlinesQuery && airlinesQueryValue.length > 0 && (
              <div className="responsive-scrollbar absolute z-30 mt-2 flex max-h-40 w-full flex-col gap-2 overflow-y-scroll rounded-lg bg-white p-2 shadow-[0_0_5px_rgba(0,0,0,0.1)] dark:bg-black">
                {airlinesQueryValue.map((item: any, i: number) => (
                  <div
                    key={i + Math.random()}
                    onClick={() => {
                      if (stateValueAirlines.length > 2) {
                        toast.error('Not Allowed', {
                          description: "You can't select more then 3 Airlines",
                          duration: 2000,
                        });
                      } else {
                        // ---> airlines state update handler <---
                        stateUpdateAirlines([...stateValueAirlines, item]),
                          setAirlinesQueryValue((prev) =>
                            prev.filter(
                              (airline) => airline.code !== item.code,
                            ),
                          );
                      }
                    }}
                    className={`flex cursor-pointer items-center justify-between gap-1 rounded-lg border border-gray-200 px-2 py-1 text-xs font-medium hover:border-gray-300 ${
                      stateValueAirlines.includes(item.code)
                        ? 'border-gray-400 bg-gray-100'
                        : ''
                    }`}
                  >
                    <div className="flex items-center gap-1">
                      <h4>{item?.code}</h4>
                      <h4>-</h4>
                      <h4>{item?.name}</h4>
                    </div>
                    <Image
                      src={`${item.logo}`}
                      alt="Airlines Logo"
                      height={20}
                      width={30}
                      className="h-5 rounded object-contain"
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
};

export default TravellerFields;
