const dateConverter = (dateObject: any) => {
  // Extract year, month, and day from the object
  const { year, month, day } = dateObject;

  // Get the month name
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const monthName = months[month - 1]; // Months in JavaScript are 0-indexed, so subtract 1

  // Get the day of the week name
  const daysOfWeek = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  const dayOfWeek = new Date(year, month - 1, day).getDay(); // Months are 0-indexed

  return {
    date: `${year}-${month}-${day}`,
    day,
    month,
    monthName,
    year,
    weekName: daysOfWeek[dayOfWeek],
  };
};

export default dateConverter;
