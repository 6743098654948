import HistoryTableRow from '@/components/layout/home/HistoryTableRow';
import CustomDropdown from '@/components/custom/CustomDropdown';
import CustomPagination from '@/components/custom/Pagination/CustomPagination';
import {
  CustomTable,
  CustomTBody,
  CustomTCell,
  CustomTHead,
  CustomTRow,
} from '@/components/custom/Table';
import usePagination from '@/hooks/PaginationLogic';
import { useEffect, useState } from 'react';

const options = [
  { value: 4, label: '4' },
  { value: 6, label: '6' },
  { value: 8, label: '8' },
  { value: 10, label: '10' },
  { value: 12, label: '12' },
];

const SearchHistoryTab = ({ allData, allAirportsData }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(6);

  const { currentItems, totalPages, startIndex, endIndex, totalItems } =
    usePagination(allData?.reverse(), pageLimit, currentPage);

  useEffect(() => {
    if (totalPages && currentPage > totalPages) {
      setCurrentPage(totalPages);
    }
  }, [totalPages]);

  return (
    <div className="mt-5 border-t border-gray-200 pt-5">
      <CustomTable className="whitespace-nowrap rounded-lg">
        <CustomTHead className="bg-stone-100">
          <CustomTCell>From</CustomTCell>
          <CustomTCell>To</CustomTCell>
          <CustomTCell>Type</CustomTCell>
          <CustomTCell>Departure Date</CustomTCell>
          <CustomTCell>Return Date</CustomTCell>
          <CustomTCell>Class</CustomTCell>
          <CustomTCell>Traveller</CustomTCell>
          <CustomTCell className="w-14">Action</CustomTCell>
        </CustomTHead>
        <CustomTBody>
          {currentItems.length > 0 ? (
            currentItems?.map((history: any, index: React.Key) => (
              <HistoryTableRow
                history={history}
                allAirportsData={allAirportsData}
                key={index}
              />
            ))
          ) : (
            <>
              <CustomTRow>
                <CustomTCell></CustomTCell>
              </CustomTRow>
              <CustomTRow>
                <CustomTCell className="text-base font-medium">
                  No History Found !
                </CustomTCell>
              </CustomTRow>
              <CustomTRow>
                <CustomTCell></CustomTCell>
              </CustomTRow>
            </>
          )}
        </CustomTBody>
      </CustomTable>

      <div className="mt-3 flex w-full flex-col justify-between gap-3 rounded-md border border-gray-200 bg-black/5 px-2 py-[6px] xs:px-4 md:flex-row md:items-center md:gap-5 2xl:mt-5">
        <div className="flex items-center gap-2 whitespace-nowrap">
          <div className="flex items-center gap-2">
            <h3 className="text-xs font-medium text-gray-500">Show Per Page</h3>
            <CustomDropdown
              options={options}
              defaultOption={pageLimit}
              onOpenChange={setPageLimit}
              placement="top"
            />
          </div>
          <h3 className="text-xs font-medium text-gray-500">
            <span className="hidden xs:inline-block">Showing</span>{' '}
            <span className="text-[13px] font-semibold">
              {totalItems ? startIndex : 0}
            </span>
            -<span className="text-[13px] font-semibold">{endIndex}</span> of{' '}
            <span className="text-[13px] font-semibold">{totalItems}</span>{' '}
            items
          </h3>
        </div>
        {/* pagination */}
        <CustomPagination
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={totalPages}
        />
      </div>
    </div>
  );
};

export default SearchHistoryTab;
