import { useEffect, useState } from 'react';
import { Calendar } from '@/components/ui/calendar';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import dateConverter from '@/utils/dateConverter';
import { cn } from '@/utils/utils';

const today = new Date();
today.setHours(0, 0, 0, 0);

const DepartureReturnDate = ({
  label,
  stateValue,
  stateUpdateValue,
  disabled,
}) => {
  const [isDateOpen, setIsDateOpen] = useState(false);
  const [initialDate, setInitialDate] = useState(
    new Date(
      Number(stateValue?.date.slice(0, 4)),
      Number(stateValue?.date.slice(5, 7)) - 1,
      Number(stateValue?.date.slice(8, 10)),
    ),
  );

  useEffect(() => {
    setInitialDate(
      new Date(
        Number(stateValue?.date.slice(0, 4)),
        Number(stateValue?.date.slice(5, 7)) - 1,
        Number(stateValue?.date.slice(8, 10)),
      ),
    );
  }, [stateValue]);

  // Updated: Ensure `disabledDate` is either a valid date or today.
  const disabledDate = disabled
    ? new Date(disabled.year, disabled.month - 1, disabled.day)
    : today;

  const handleUpdateDate = (date) => {
    const convertingDate = dateConverter({
      year: date.getFullYear(),
      month: (date.getMonth() + 1).toString().padStart(2, '0'),
      day: date.getDate().toString().padStart(2, '0'),
    });
    setInitialDate(date);
    stateUpdateValue(convertingDate);
    setIsDateOpen(false);
  };

  return (
    <Popover open={isDateOpen} onOpenChange={setIsDateOpen}>
      <PopoverTrigger asChild>
        <div
          aria-label="Date Input"
          className={cn(
            'relative flex h-12 w-full max-w-full cursor-pointer rounded-lg border bg-gray-100 lg:h-[50px] 2xl:bg-white',
            isDateOpen && 'border-blue-600',
          )}
        >
          <div className="flex h-full w-11 items-center justify-center overflow-hidden whitespace-nowrap border-r font-semibold lg:w-14 2xl:w-11">
            {stateValue?.day}
          </div>
          <div className="flex min-w-0 flex-1 flex-col justify-center pl-[10px] pr-3">
            <p className="truncate text-sm font-semibold">
              {stateValue?.monthName}
            </p>
            <p className="truncate text-xs">{`${stateValue?.weekName}, ${stateValue?.year}`}</p>
          </div>
          <p
            className={cn(
              'absolute -top-[7px] left-2 select-none rounded-full border bg-white px-1 text-[8px] uppercase tracking-wide lg:text-[9px] 2xl:border-none 2xl:bg-white',
              isDateOpen && 'border-blue-600 text-blue-600',
            )}
          >
            {label}
          </p>
        </div>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0">
        <Calendar
          mode="single"
          selected={initialDate}
          defaultMonth={initialDate}
          onSelect={handleUpdateDate}
          disabled={{
            before: disabledDate,
          }}
          initialFocus
        />
      </PopoverContent>
    </Popover>
  );
};

export default DepartureReturnDate;
