const getCurrentOrFutureDate = (days = 0) => {
  const currentDate = new Date();
  const newDate = new Date(currentDate);
  newDate.setDate(currentDate.getDate() + days);

  const year = newDate.getFullYear();
  const month = (newDate.getMonth() + 1).toString().padStart(2, '0');
  const day = newDate.getDate().toString().padStart(2, '0');

  return { year, month, day };
};

export default getCurrentOrFutureDate;
