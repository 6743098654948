import { Loader2 } from 'lucide-react';
import { useRouter } from 'next/navigation';
import { useEffect, useState } from 'react';
import { toast } from 'sonner';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import {
  flightSearchClose,
  flightSearchFailed,
  flightSearchQuery,
  flightSearchStart,
} from '@/store/slices/flightSearchSlice';
import {
  setMultiCityStateAirlines,
  setMultiCityStateDestination,
  setMultiCityStateOrigin,
} from '@/store/slices/multicitySlice';
import {
  setOnewayStateAirlines,
  setOnewayStateCabinClass,
  setOnewayStateDeparture,
  setOnewayStateDestination,
  setOnewayStateOrigin,
  setOnewayStatePassenger,
  swapOnewayOriginAndDestination,
} from '@/store/slices/oneWaySlice';
import {
  setReturnStateAirlines,
  setReturnStateCabinClass,
  setReturnStateDeparture,
  setReturnStateDestination,
  setReturnStateOrigin,
  setReturnStatePassenger,
  setReturnStateReturn,
  swapReturnOriginAndDestination,
} from '@/store/slices/returnSlice';
import { AiOutlineSwap } from '@/Icons/ai';
import { IoMdClose } from '@/Icons/io';
import { IoSearch } from '@/Icons/io5';
import OriginDestinationFields from '../OriginDestinationFields';
import TravellerFields from '../TravellerFields';
import DepartureReturnDate from '../DepartureReturnDate';
import usePublicFetcher from '@/hooks/usePublicFetcher';

const ReturnSearchTab = () => {
  // redux states
  const oneWayState = useAppSelector((state) => state.oneWayState);
  const returnState = useAppSelector((state) => state.returnState);
  const allAirportsData = useAppSelector(
    (state) => state.airportsData.airports as any,
  );
  const isLogged = useAppSelector((state) => state.userData.isLogged);
  const isLoading = useAppSelector((state) => state.flightSearch.isLoading);

  // use states
  // input query states
  const [originInputQuery, setOriginInputQuery] = useState<any>(
    returnState.routes[0].origin.city,
  );
  const [destinationInputQuery, setDestinationInputQuery] = useState<any>(
    returnState.routes[0].destination.city,
  );

  // query data states
  const [originQueryData, setOriginQueryData] = useState<any>(null);
  const [destinationQueryData, setDestinationQueryData] = useState<any>(null);

  //
  const [isSwap, setIsSwap] = useState(false);

  //   constants
  const dispatch = useAppDispatch();
  const router = useRouter();

  const totalPassenger =
    returnState?.passenger?.adult +
    returnState?.passenger?.child +
    returnState?.passenger?.infant;

  // ---> airports data filtering handler <---
  useEffect(() => {
    const filterAirports = (query: string, setQuery) => {
      if (query?.length >= 2) {
        const filteredAirports: any = allAirportsData?.filter(
          (airport) =>
            airport.code.toLowerCase().includes(query.toLowerCase()) ||
            airport.country.code.toLowerCase().includes(query.toLowerCase()) ||
            airport.city.toLowerCase().includes(query.toLowerCase()),
        );

        // Prioritize airports whose code includes the query
        const prioritizedAirports = filteredAirports?.sort((a, b) => {
          const aCodeMatch = a.code.toLowerCase().includes(query.toLowerCase());
          const bCodeMatch = b.code.toLowerCase().includes(query.toLowerCase());

          if (aCodeMatch && !bCodeMatch) {
            return -1; // a should come before b
          }
          if (!aCodeMatch && bCodeMatch) {
            return 1; // b should come before a
          }
          return 0; // no sorting preference
        });

        setQuery(prioritizedAirports);
      } else {
        setQuery([]);
      }
    };

    filterAirports(originInputQuery, setOriginQueryData);
    filterAirports(destinationInputQuery, setDestinationQueryData);
  }, [originInputQuery, destinationInputQuery, allAirportsData]);

  // ---> origin state update handler <---
  const handleOriginStateUpdate = (value) => {
    dispatch(setOnewayStateOrigin(value));
    dispatch(setReturnStateOrigin(value));
    dispatch(setMultiCityStateOrigin({ origin: value, routeNo: 0 }));
  };

  // ---> destination state update handler <---
  const handleDestinationStateUpdate = (value) => {
    dispatch(setOnewayStateDestination(value));
    dispatch(setReturnStateDestination(value));
    dispatch(setMultiCityStateDestination({ destination: value, routeNo: 0 }));
  };

  // ---> departure state update handler <---
  const handleDepartureStateUpdate = (value) => {
    dispatch(setOnewayStateDeparture(value));
    dispatch(setReturnStateDeparture(value));
    dispatch(setReturnStateReturn(value));
  };

  // ---> return state update handler <---
  const handleReturnStateUpdate = (value) => {
    dispatch(setReturnStateReturn(value));
  };

  // ---> passenger state update handler <---
  const handlePassengerStateUpdate = (value: number, type: string) => {
    const onewayPassenger = { ...oneWayState.passenger };
    const returnPassenger = { ...returnState.passenger };

    if (type === 'adult') {
      onewayPassenger.adult = onewayPassenger.adult + value;
      returnPassenger.adult = returnPassenger.adult + value;
    }
    if (type === 'child') {
      onewayPassenger.child = onewayPassenger.child + value;
      returnPassenger.child = returnPassenger.child + value;
    }
    if (type === 'infant') {
      onewayPassenger.infant = onewayPassenger.infant + value;
      returnPassenger.infant = returnPassenger.infant + value;
    }

    dispatch(setOnewayStatePassenger(onewayPassenger));
    dispatch(setReturnStatePassenger(returnPassenger));
  };

  // ---> cabin state update handler <---
  const handleCabinStateUpdate = (value) => {
    dispatch(setOnewayStateCabinClass(value));
    dispatch(setReturnStateCabinClass(value));
  };

  // ---> airlines state update handler <---
  const handleAirlinesStateUpdate = (value) => {
    dispatch(setOnewayStateAirlines(value));
    dispatch(setReturnStateAirlines(value));
    dispatch(setMultiCityStateAirlines(value));
  };

  // ---> swap handler <---
  const handleSwap = () => {
    setIsSwap(!isSwap);
    dispatch(swapOnewayOriginAndDestination());
    dispatch(swapReturnOriginAndDestination());
  };

  const HandleReturnSearch = async () => {
    dispatch(flightSearchStart());

    const modifiedData = {
      filter: {
        directFlightOnly: returnState?.filter?.directFlightOnly ?? false,
        cabinClass:
          returnState?.filter?.cabinClass?.category.toLowerCase() ?? '',
        airline:
          returnState?.filter?.airline?.map((airline) => airline?.code) ?? [],
      },
      routes: returnState?.routes?.map((route) => ({
        origin: route?.origin?.code,
        destination: route?.destination?.code,
        departureDate: route?.departureDate.date,
      })),
      passenger: returnState?.passenger,
    };

    try {
      if (!isLogged) {
        const response = await usePublicFetcher(
          `v2/searchNoUser2`,
          modifiedData,
        );

        if (response.status !== 'error') {
          dispatch(
            flightSearchQuery({
              data: response,
              details: returnState,
            }),
          );

          localStorage.setItem(
            'flightSearchQuery',
            JSON.stringify({
              data: response,
              details: returnState,
            }),
          );

          router.push(`/flight-search?sid=${response._id}&type=return`);
        } else {
          dispatch(flightSearchFailed(response?.error));
          toast.error(`Error: ${response.message}`, {
            duration: 3000,
          });
        }
      } else {
        const response = await usePublicFetcher(`v2/search2`, modifiedData);

        if (response.status !== 'error') {
          dispatch(
            flightSearchQuery({
              data: response,
              details: returnState,
            }),
          );

          localStorage.setItem(
            'flightSearchQuery',
            JSON.stringify({
              data: response,
              details: returnState,
            }),
          );

          router.push(`/flight-search?sid=${response._id}&type=return`);
        } else {
          dispatch(flightSearchFailed(response?.error));
          toast.error(`Error: ${response.error.message}`, {
            duration: 3000,
          });
        }
      }
    } catch (error: any) {
      dispatch(flightSearchFailed(error));
      toast(`Error: ${error?.message}`, {
        duration: 3000,
      });
    }
  };

  return (
    <div className="">
      <div className="grid gap-[14px] 2xl:grid-cols-5">
        {/* column one  */}
        <div className="relative grid gap-[14px] 2xl:col-span-2 2xl:grid-cols-2">
          {/* ---> origin field <--- */}
          <OriginDestinationFields
            label="origin"
            stateValue={returnState?.routes[0]?.origin}
            setInputQuery={setOriginInputQuery}
            queryData={originQueryData}
            stateUpdateValue={handleOriginStateUpdate}
          />

          {/* ---> swap button <--- */}
          <div
            className={`absolute right-2 top-1/2 z-20 flex size-9 -translate-y-1/2 cursor-pointer items-center justify-center rounded-full bg-white 2xl:left-1/2 2xl:-translate-x-1/2 2xl:border-1.5`}
          >
            <p className="absolute hidden h-10 w-[14px] bg-white 2xl:block"></p>
            <button
              onClick={handleSwap}
              aria-label="Swap items"
              className={`flex size-7 items-center justify-center rounded-full border border-gray-200 bg-gray-50 text-black hover:bg-gray-100 ${
                isSwap ? 'rotate-180' : 'rotate-0'
              } duration-200 ease-in-out`}
            >
              <AiOutlineSwap />
            </button>
          </div>

          {/* ---> destination field <--- */}
          <OriginDestinationFields
            label="destination"
            stateValue={returnState?.routes[0]?.destination}
            setInputQuery={setDestinationInputQuery}
            queryData={destinationQueryData}
            stateUpdateValue={handleDestinationStateUpdate}
          />
        </div>

        <div className="grid grid-cols-2 gap-x-1 gap-y-[14px] lg:gap-[14px] 2xl:col-span-2">
          <DepartureReturnDate
            label="DEPARTURE DATE"
            stateValue={returnState?.routes[0]?.departureDate}
            stateUpdateValue={handleDepartureStateUpdate}
            disabled=""
          />
          <DepartureReturnDate
            label="RETURN DATE"
            stateValue={returnState?.routes[1]?.departureDate}
            stateUpdateValue={handleReturnStateUpdate}
            disabled={returnState?.routes[0]?.departureDate}
          />
        </div>

        {/* ---> traveller field <--- */}
        <TravellerFields
          label="TRAVELLER, CLASS"
          stateValue={returnState}
          stateUpdatePassenger={handlePassengerStateUpdate}
          stateUpdateCabin={handleCabinStateUpdate}
          stateUpdateAirlines={handleAirlinesStateUpdate}
        />
      </div>

      {/* ---> search button <--- */}
      <div className="mt-5 flex items-center justify-end gap-5">
        {isLoading && (
          <button
            onClick={() => {
              dispatch(flightSearchClose());
            }}
            className="flex h-10 w-full items-center justify-center gap-1 rounded-lg bg-tertiary_color text-sm font-medium text-white duration-250 ease-in-out hover:bg-tertiary_color/80 active:scale-95 2xl:w-28"
          >
            <IoMdClose className="text-xl" /> Close
          </button>
        )}

        {isLoading ? (
          <button className="flex h-10 w-full items-center justify-center rounded-lg bg-primary_color text-white transition-colors duration-250 ease-in-out hover:bg-primary_color/90 2xl:w-28">
            <Loader2 className="h-5 w-full animate-spin 2xl:w-20" />
          </button>
        ) : totalPassenger < 10 &&
          (returnState.routes[0] as any).departureDate ? (
          <button
            onClick={() => HandleReturnSearch()}
            className="flex h-10 w-full items-center justify-center gap-1 rounded-lg bg-primary_color text-sm font-medium text-white duration-250 ease-in-out hover:bg-primary_color/90 active:scale-95 2xl:w-28"
          >
            <IoSearch className="text-xl" /> Search
          </button>
        ) : (
          <button className="flex h-10 w-full items-center justify-center gap-1 rounded-lg bg-gray-400 text-sm font-medium text-white duration-250 ease-in-out active:scale-95 2xl:w-28">
            <IoSearch className="text-xl" /> Search
          </button>
        )}
      </div>
    </div>
  );
};

export default ReturnSearchTab;
