import { Loader2 } from 'lucide-react';
import { useRouter } from 'next/navigation';
import { useEffect, useState } from 'react';
import { toast } from 'sonner';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import {
  flightSearchClose,
  flightSearchFailed,
  flightSearchQuery,
  flightSearchStart,
} from '@/store/slices/flightSearchSlice';
import {
  addMultiCityRoute,
  setMultiCityStateAirlines,
  setMultiCityStateCabinClass,
  setMultiCityStatePassenger,
} from '@/store/slices/multicitySlice';
import {
  setOnewayStateAirlines,
  setOnewayStateCabinClass,
  setOnewayStatePassenger,
} from '@/store/slices/oneWaySlice';
import {
  setReturnStateAirlines,
  setReturnStateCabinClass,
  setReturnStatePassenger,
} from '@/store/slices/returnSlice';
import { FaPlus } from '@/Icons/fa6';
import { IoMdClose } from '@/Icons/io';
import { IoSearch } from '@/Icons/io5';
import TravellerFields from '../TravellerFields';
import MulticityFlight from './MulticityFlight';
import usePublicFetcher from '@/hooks/usePublicFetcher';

const MulticitySearchTab = () => {
  // redux states
  const oneWayState = useAppSelector((state) => state.oneWayState);
  const returnState = useAppSelector((state) => state.returnState);
  const multiCityState = useAppSelector((state) => state.multiCityState);
  const allAirportsData = useAppSelector(
    (state) => state.airportsData.airports as any,
  );
  const isLogged = useAppSelector((state) => state.userData.isLogged);
  const isLoading = useAppSelector((state) => state.flightSearch.isLoading);

  // use states
  // input query states
  const [originInputQuery, setOriginInputQuery] = useState<any>(
    returnState.routes[0].origin.city,
  );
  const [destinationInputQuery, setDestinationInputQuery] = useState<any>(
    returnState.routes[0].destination.city,
  );

  // query data states
  const [originQueryData, setOriginQueryData] = useState<any>(null);
  const [destinationQueryData, setDestinationQueryData] = useState<any>(null);

  //   constants
  const dispatch = useAppDispatch();
  const router = useRouter();

  const totalPassenger =
    multiCityState?.passenger?.adult +
    multiCityState?.passenger?.child +
    multiCityState?.passenger?.infant;

  // use effects

  // ---> airports data filtering handler <---
  useEffect(() => {
    const filterAirports = (query: string, setQuery) => {
      if (query?.length >= 2) {
        const filteredAirports: any = allAirportsData?.filter(
          (airport) =>
            airport.code.toLowerCase().includes(query.toLowerCase()) ||
            airport.country.code.toLowerCase().includes(query.toLowerCase()) ||
            airport.city.toLowerCase().includes(query.toLowerCase()),
        );

        // Prioritize airports whose code includes the query
        const prioritizedAirports = filteredAirports?.sort((a, b) => {
          const aCodeMatch = a.code.toLowerCase().includes(query.toLowerCase());
          const bCodeMatch = b.code.toLowerCase().includes(query.toLowerCase());

          if (aCodeMatch && !bCodeMatch) {
            return -1; // a should come before b
          }
          if (!aCodeMatch && bCodeMatch) {
            return 1; // b should come before a
          }
          return 0; // no sorting preference
        });

        setQuery(prioritizedAirports);
      } else {
        setQuery([]);
      }
    };

    filterAirports(originInputQuery, setOriginQueryData);
    filterAirports(destinationInputQuery, setDestinationQueryData);
  }, [originInputQuery, destinationInputQuery, allAirportsData]);

  // ---> passenger state update handler <---
  const handlePassengerStateUpdate = (value: number, type: string) => {
    const onewayPassenger = { ...oneWayState.passenger };
    const returnPassenger = { ...returnState.passenger };
    const multiCityPassenger = { ...multiCityState.passenger };

    if (type === 'adult') {
      onewayPassenger.adult = onewayPassenger.adult + value;
      returnPassenger.adult = returnPassenger.adult + value;
      multiCityPassenger.adult = multiCityPassenger.adult + value;
    }
    if (type === 'child') {
      onewayPassenger.child = onewayPassenger.child + value;
      returnPassenger.child = returnPassenger.child + value;
      multiCityPassenger.child = multiCityPassenger.child + value;
    }
    if (type === 'infant') {
      onewayPassenger.infant = onewayPassenger.infant + value;
      returnPassenger.infant = returnPassenger.infant + value;
      multiCityPassenger.infant = multiCityPassenger.infant + value;
    }

    dispatch(setOnewayStatePassenger(onewayPassenger));
    dispatch(setReturnStatePassenger(returnPassenger));
    dispatch(setMultiCityStatePassenger(multiCityPassenger));
  };

  // ---> cabin state update handler <---
  const handleCabinStateUpdate = (value: {
    id: string;
    category: string;
    label: string;
    shortCode: string;
  }) => {
    dispatch(setOnewayStateCabinClass(value));
    dispatch(setReturnStateCabinClass(value));
    dispatch(setMultiCityStateCabinClass(value));
  };

  // ---> airlines state update handler <---
  const handleAirlinesStateUpdate = (
    value: { code: string; logo: string; name: string }[],
  ) => {
    dispatch(setOnewayStateAirlines(value));
    dispatch(setReturnStateAirlines(value));
    dispatch(setMultiCityStateAirlines(value));
  };

  const HandleMultiCitySearch = async () => {
    dispatch(flightSearchStart());

    const modifiedData = {
      filter: {
        directFlightOnly: multiCityState?.filter?.directFlightOnly ?? false,
        cabinClass:
          multiCityState?.filter?.cabinClass?.category.toLowerCase() ?? '',
        airline:
          multiCityState?.filter?.airline?.map((airline) => airline?.code) ??
          [],
      },
      routes:
        multiCityState?.routes?.map((route) => ({
          origin: route?.origin?.code ?? '',
          destination: route?.destination?.code ?? '',
          departureDate: route?.departureDate?.date ?? '',
        })) ?? [],
      passenger: multiCityState?.passenger ?? {},
    };

    try {
      if (!isLogged) {
        const response = await usePublicFetcher(
          `v2/searchNoUser2`,
          modifiedData,
        );

        if (response.status !== 'error') {
          dispatch(
            flightSearchQuery({
              data: response,
              details: multiCityState,
            }),
          );

          localStorage.setItem(
            'flightSearchQuery',
            JSON.stringify({
              data: response,
              details: multiCityState,
            }),
          );

          router.push(`/flight-search?sid=${response._id}&type=multicity`);
        } else {
          dispatch(flightSearchFailed(response?.error));
          toast.error(`Error: ${response.message}`, {
            duration: 3000,
          });
        }
      } else {
        const response = await usePublicFetcher(`v2/search2`, modifiedData);

        if (response.status !== 'error') {
          dispatch(
            flightSearchQuery({
              data: response,
              details: multiCityState,
            }),
          );

          localStorage.setItem(
            'flightSearchQuery',
            JSON.stringify({
              data: response,
              details: multiCityState,
            }),
          );

          router.push(`/flight-search?sid=${response._id}&type=multicity`);
        } else {
          dispatch(flightSearchFailed(response?.error));
          toast.error(`Error: ${response.error.message}`, {
            duration: 3000,
          });
        }
      }
    } catch (error: any) {
      dispatch(flightSearchFailed(error));
      toast(`Error: ${error?.message}`, {
        duration: 3000,
      });
    }
  };

  return (
    <div className="space-y-[14px]">
      {multiCityState?.routes?.map((route, index) => (
        <MulticityFlight
          key={index}
          index={index}
          route={route}
          oneWayState={oneWayState}
          returnState={returnState}
          multiCityState={multiCityState}
          setOriginInputQuery={setOriginInputQuery}
          setDestinationInputQuery={setDestinationInputQuery}
          originQueryData={originQueryData}
          destinationQueryData={destinationQueryData}
        />
      ))}
      {/* ---> traveller field <--- */}
      <div className="w-full min-w-0 pb-2 2xl:hidden">
        {/* ---> add flight route field <--- */}
        {multiCityState?.routes?.length < 5 ? (
          <button
            onClick={() => dispatch(addMultiCityRoute())}
            className="flex h-8 w-full items-center justify-center gap-1 rounded-full bg-primary_color text-xs font-medium text-white transition-colors duration-300 ease-linear hover:bg-primary_color/90"
          >
            <FaPlus className="text-sm" />
            Add Flight
          </button>
        ) : (
          <button className="flex h-8 w-full cursor-not-allowed items-center justify-center gap-1 rounded-full bg-primary_color/50 text-xs font-medium text-white">
            <FaPlus className="text-sm" />
            Add Flight
          </button>
        )}
      </div>

      {/* ---> traveller field <--- */}
      <div className="w-full min-w-0 2xl:hidden">
        <TravellerFields
          label="TRAVELLER, CLASS"
          stateValue={multiCityState}
          stateUpdateAirlines={handleAirlinesStateUpdate}
          stateUpdatePassenger={handlePassengerStateUpdate}
          stateUpdateCabin={handleCabinStateUpdate}
        />
      </div>

      <div className="my-5 flex items-center justify-between">
        {/* ---> add flight route field <--- */}
        {multiCityState?.routes?.length < 5 ? (
          <button
            onClick={() => dispatch(addMultiCityRoute())}
            className="hidden h-10 w-full items-center justify-center gap-1 rounded-lg bg-primary_color text-sm font-medium text-white duration-250 ease-in-out hover:bg-primary_color/90 active:scale-95 2xl:flex 2xl:w-32"
          >
            <FaPlus />
            Add Flight
          </button>
        ) : (
          <button className="hidden h-10 w-full cursor-not-allowed items-center justify-center gap-1 rounded-lg bg-primary_color/50 text-sm font-medium text-white 2xl:flex 2xl:w-32">
            <FaPlus />
            Add Flight
          </button>
        )}

        {/* ---> search button <--- */}
        <div className="flex w-full items-center justify-end gap-5">
          {isLoading && (
            <button
              onClick={() => {
                dispatch(flightSearchClose());
              }}
              className="flex h-10 w-full items-center justify-center gap-1 rounded-lg bg-tertiary_color text-sm font-medium text-white duration-250 ease-in-out hover:bg-tertiary_color/80 active:scale-95 2xl:w-28"
            >
              <IoMdClose className="text-xl" /> Close
            </button>
          )}

          {isLoading ? (
            <button className="flex h-10 w-full items-center justify-center rounded-lg bg-primary_color text-white transition-colors duration-250 ease-in-out hover:bg-primary_color/90 2xl:w-28">
              <Loader2 className="h-5 w-full animate-spin 2xl:w-20" />
            </button>
          ) : totalPassenger < 10 &&
            (multiCityState.routes[0] as any).departureDate ? (
            <button
              onClick={() => HandleMultiCitySearch()}
              className="flex h-10 w-full items-center justify-center gap-1 rounded-lg bg-primary_color text-sm font-medium text-white duration-250 ease-in-out hover:bg-primary_color/90 active:scale-95 2xl:w-28"
            >
              <IoSearch className="text-xl" /> Search
            </button>
          ) : (
            <button className="flex h-10 w-full items-center justify-center gap-1 rounded-lg bg-gray-400 text-sm font-medium text-white duration-250 ease-in-out active:scale-95 2xl:w-28">
              <IoSearch className="text-xl" /> Search
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default MulticitySearchTab;
