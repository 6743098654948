import { useState } from 'react';
import DepartureReturnDate from '../DepartureReturnDate';
import OriginDestinationFields from '../OriginDestinationFields';
import TravellerFields from '../TravellerFields';
import { useAppDispatch } from '@/store/hooks';
import {
  removeMultiCityRoute,
  setMultiCityStateAirlines,
  setMultiCityStateCabinClass,
  setMultiCityStateDeparture,
  setMultiCityStateDestination,
  setMultiCityStateOrigin,
  setMultiCityStatePassenger,
  swapMultiCityOriginAndDestination,
} from '@/store/slices/multicitySlice';
import {
  setOnewayStateAirlines,
  setOnewayStateCabinClass,
  setOnewayStatePassenger,
} from '@/store/slices/oneWaySlice';
import {
  setReturnStateAirlines,
  setReturnStateCabinClass,
  setReturnStatePassenger,
} from '@/store/slices/returnSlice';
import { AiOutlineSwap } from '@/Icons/ai';
import { FaPlus } from '@/Icons/fa6';
import React from 'react';

const MulticityFlight = ({
  index,
  route,
  oneWayState,
  returnState,
  multiCityState,
  setOriginInputQuery,
  setDestinationInputQuery,
  originQueryData,
  destinationQueryData,
}) => {
  const [isSwap, setIsSwap] = useState(false);

  //   constants
  const dispatch = useAppDispatch();

  // ---> origin state update handler <---
  const handleOriginStateUpdate = (value: any) => {
    dispatch(setMultiCityStateOrigin({ origin: value, routeNo: index }));
  };

  // ---> destination state update handler <---
  const handleDestinationStateUpdate = (value: any) => {
    dispatch(
      setMultiCityStateDestination({ destination: value, routeNo: index }),
    );
  };

  // ---> departure state update handler <---
  const handleDepartureStateUpdate = (value: any) => {
    dispatch(
      setMultiCityStateDeparture({ departureDate: value, routeNo: index }),
    );
  };

  // ---> passenger state update handler <---
  const handlePassengerStateUpdate = (value: number, type: string) => {
    const onewayPassenger = { ...oneWayState.passenger };
    const returnPassenger = { ...returnState.passenger };
    const multiCityPassenger = { ...multiCityState.passenger };

    if (type === 'adult') {
      onewayPassenger.adult = onewayPassenger.adult + value;
      returnPassenger.adult = returnPassenger.adult + value;
      multiCityPassenger.adult = multiCityPassenger.adult + value;
    }
    if (type === 'child') {
      onewayPassenger.child = onewayPassenger.child + value;
      returnPassenger.child = returnPassenger.child + value;
      multiCityPassenger.child = multiCityPassenger.child + value;
    }
    if (type === 'infant') {
      onewayPassenger.infant = onewayPassenger.infant + value;
      returnPassenger.infant = returnPassenger.infant + value;
      multiCityPassenger.infant = multiCityPassenger.infant + value;
    }

    dispatch(setOnewayStatePassenger(onewayPassenger));
    dispatch(setReturnStatePassenger(returnPassenger));
    dispatch(setMultiCityStatePassenger(multiCityPassenger));
  };

  // ---> cabin state update handler <---
  const handleCabinStateUpdate = (value: {
    id: string;
    category: string;
    label: string;
    shortCode: string;
  }) => {
    dispatch(setOnewayStateCabinClass(value));
    dispatch(setReturnStateCabinClass(value));
    dispatch(setMultiCityStateCabinClass(value));
  };

  // ---> airlines state update handler <---
  const handleAirlinesStateUpdate = (
    value: { code: string; logo: string; name: string }[],
  ) => {
    dispatch(setOnewayStateAirlines(value));
    dispatch(setReturnStateAirlines(value));
    dispatch(setMultiCityStateAirlines(value));
  };

  // ---> swap handler <---
  const handleSwap = () => {
    setIsSwap(!isSwap);
    dispatch(swapMultiCityOriginAndDestination(index));
  };

  return (
    <div className="flex flex-col items-center justify-between gap-[14px] 2xl:flex-row">
      <div className="mt-[2px] flex w-full items-center whitespace-nowrap 2xl:hidden">
        <h4 className="rounded-full bg-primary_color px-2 py-1 text-[10px] leading-none text-white">
          {index === 0
            ? `${index + 1}st`
            : index === 1
            ? `${index + 1}nd`
            : index === 2
            ? `${index + 1}rd`
            : index === 3
            ? `${index + 1}th`
            : index === 4 && `${index + 1}th`}{' '}
          Flight
        </h4>
        <p className="flex-1 border-b-[.5px]"></p>
        {index === 0 || index === 1 ? (
          <button className="flex items-center justify-center gap-1 rounded-lg border px-2 py-1 text-[10px] font-medium leading-none transition-colors duration-300 ease-linear">
            <FaPlus className="mt-[2px] rotate-45 text-xs" /> Remove
          </button>
        ) : (
          <button
            onClick={() => dispatch(removeMultiCityRoute(index))}
            className="flex items-center justify-center gap-1 rounded-lg border border-red-300 px-2 py-1 text-[10px] font-medium leading-none text-red-600 transition-colors duration-300 ease-linear"
          >
            <FaPlus className="mt-[2px] rotate-45 text-xs" /> Remove
          </button>
        )}
      </div>
      {/* column one  */}
      <div className="relative flex w-full min-w-0 flex-[4] flex-col items-center justify-between gap-[14px] 2xl:flex-row">
        {/* ---> origin field <--- */}
        <div className="w-full min-w-0 flex-1">
          <OriginDestinationFields
            label="origin"
            stateValue={route?.origin}
            setInputQuery={setOriginInputQuery}
            queryData={originQueryData}
            stateUpdateValue={handleOriginStateUpdate}
          />
        </div>

        {/* ---> swap button <--- */}
        <div
          className={`absolute right-2 top-1/2 z-20 flex size-9 -translate-y-1/2 cursor-pointer items-center justify-center rounded-full bg-white 2xl:left-1/2 2xl:-translate-x-1/2 2xl:border-1.5`}
        >
          <p className="absolute hidden h-10 w-[14px] bg-white 2xl:block"></p>
          <button
            onClick={handleSwap}
            aria-label="Swap items"
            className={`flex size-7 items-center justify-center rounded-full border border-gray-200 bg-gray-50 text-black hover:bg-gray-100 ${
              isSwap ? 'rotate-180' : 'rotate-0'
            } duration-200 ease-in-out`}
          >
            <AiOutlineSwap />
          </button>
        </div>

        {/* ---> destination field <--- */}
        <div className="w-full min-w-0 flex-1">
          <OriginDestinationFields
            label="destination"
            stateValue={route?.destination}
            setInputQuery={setDestinationInputQuery}
            queryData={destinationQueryData}
            stateUpdateValue={handleDestinationStateUpdate}
          />
        </div>
      </div>

      {/* column two */}
      <div className="flex w-full min-w-0 flex-[3] items-center justify-between gap-[14px]">
        {/* ---> departure date field <--- */}
        <div className="w-full min-w-0 flex-1">
          <DepartureReturnDate
            label="DEPARTURE DATE"
            stateValue={route?.departureDate}
            stateUpdateValue={handleDepartureStateUpdate}
            disabled={
              index === 0
                ? ''
                : multiCityState?.routes?.[index - 1]?.departureDate
            }
          />
        </div>

        {index === 0 ? (
          <>
            {/* ---> traveller field <--- */}
            <div className="hidden w-full min-w-0 flex-1 2xl:block">
              <TravellerFields
                label="TRAVELLER, CLASS"
                stateValue={multiCityState}
                stateUpdateAirlines={handleAirlinesStateUpdate}
                stateUpdatePassenger={handlePassengerStateUpdate}
                stateUpdateCabin={handleCabinStateUpdate}
              />
            </div>
          </>
        ) : (
          <div className="hidden w-full min-w-0 flex-1 2xl:block">
            {index === 1 ? (
              <button className="flex h-12 w-full cursor-not-allowed items-center justify-center gap-1 rounded-lg border text-sm font-medium lg:h-[50px]">
                <FaPlus className="mt-[2.5px] rotate-45" /> Remove
              </button>
            ) : (
              <button
                onClick={() => dispatch(removeMultiCityRoute(index))}
                className="flex h-12 w-full items-center justify-center gap-1 rounded-lg border border-red-300 text-sm font-medium text-red-600 transition-colors duration-300 ease-linear lg:h-[50px]"
              >
                <FaPlus className="mt-[2.5px] rotate-45" /> Remove
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default MulticityFlight;
