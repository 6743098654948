'use client';

import Image from 'next/image';
import { useEffect, useState } from 'react';

export default function OriginDestinationValue({ item, index, onClick }: any) {
  const [imgSrc, setImgSrc] = useState('');

  useEffect(() => {
    if (item?.country?.code) {
      setImgSrc(
        `https://flagcdn.com/w40/${item?.country?.code.toLowerCase()}.webp`,
      );
    }
  }, [item]);

  return (
    <div
      onClick={() => {
        if (onClick) {
          onClick(item);
        }
      }}
      className={`flex h-12 cursor-pointer items-center bg-white hover:bg-black/5 ${
        index !== 0 && 'border-t border-gray-300'
      }`}
    >
      <div className="flex h-full w-12 min-w-12 items-center justify-center">
        {imgSrc && (
          <Image
            src={imgSrc}
            alt="country icon"
            height={24}
            width={28}
            className="h-6 w-7 object-contain"
          />
        )}
      </div>
      <div className="min-w-0">
        <h3 className="truncate text-[13px] font-semibold leading-snug">
          {item?.code} - {item.city}, {item?.country?.name}
        </h3>
        <h4 className="truncate text-xs text-gray-500">{item?.name}</h4>
      </div>
    </div>
  );
}
