import { SetStateAction, useEffect, useRef, useState } from 'react';
import { IoCloseOutline } from '@/Icons/io5';
import OriginDestinationValue from './OriginDestinationValue';

const OriginDestinationFields = ({
  label,
  stateValue,
  setInputQuery,
  queryData,
  stateUpdateValue,
}) => {
  const [isInputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState(stateValue?.city || '');
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  // use effects
  useEffect(() => {
    if (isInputVisible && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isInputVisible, inputValue]);

  // ---> isInputVisible update handler <---
  const handleInputFieldClick = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setInputVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleInputFieldClick);
    return () => {
      document.removeEventListener('mousedown', handleInputFieldClick);
    };
  }, []);

  // ---> input change handler <---
  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    setInputQuery(value);
  };

  // ---> airport select handler <---
  const handleSelectAirport = (item) => {
    const selectedValue = {
      airport: item?.name,
      city: item?.city,
      country: item?.country?.name,
      code: item?.code,
    };
    setInputValue(selectedValue.city);
    stateUpdateValue(selectedValue);
    setInputVisible(false);
  };

  return (
    <div className="relative bg-white dark:bg-black">
      <div ref={containerRef}>
        {isInputVisible ? (
          <div className="relative">
            <input
              type="text"
              ref={inputRef}
              onChange={handleInputChange}
              className="h-12 w-full select-none rounded-lg border border-blue-600 bg-transparent px-[14px] font-medium transition duration-300 ease-in-out placeholder:text-sm placeholder:capitalize focus:outline-none lg:h-[50px]"
              placeholder={`Enter ${label} Here`}
              autoComplete="off"
              autoCapitalize="off"
              autoCorrect="off"
              spellCheck="false"
            />
            {inputValue && (
              <p className="absolute right-1 top-1/2 z-10 flex h-12 w-10 -translate-y-1/2 items-center rounded-full bg-white text-2xl">
                <button
                  onClick={() => {
                    setInputValue('');
                    setInputQuery('');
                  }}
                  className="rounded-full p-1 transition-colors duration-200 ease-linear hover:bg-zinc-100 active:scale-95"
                >
                  <IoCloseOutline />
                </button>
              </p>
            )}

            {queryData?.length > 0 && inputValue?.length > 0 && (
              <div className="absolute z-[999] mt-1 max-h-48 w-full overflow-y-auto rounded-lg border border-gray-200 bg-white text-sm font-medium text-gray-600 shadow-sm">
                {queryData?.map((item, index) => (
                  <OriginDestinationValue
                    key={item?._id}
                    item={item}
                    index={index}
                    onClick={(value: SetStateAction<any>) =>
                      handleSelectAirport(value)
                    }
                  />
                ))}
              </div>
            )}
          </div>
        ) : (
          <div
            className="flex h-12 cursor-pointer rounded-lg border bg-gray-100 lg:h-[50px] 2xl:bg-white"
            onClick={() => setInputVisible(true)}
          >
            <div className="flex h-full w-11 items-center justify-center overflow-hidden whitespace-nowrap border-r text-sm font-semibold lg:w-16 lg:text-base">
              {stateValue?.code}
            </div>
            <div className="flex min-w-0 flex-1 flex-col justify-center pl-[10px] pr-3">
              <p className="truncate text-sm font-semibold">
                {stateValue?.city}, {stateValue?.country}
              </p>
              <p className="truncate text-xs">{stateValue?.airport}</p>
            </div>
          </div>
        )}

        {/* input level */}
        <p
          className={`absolute -top-[7px] left-2 select-none rounded-full border bg-white px-1 text-[8px] uppercase tracking-wide lg:text-[9px] 2xl:border-none 2xl:bg-white ${
            isInputVisible ? 'border-blue-600 text-blue-600' : ''
          }`}
        >
          {label}
        </p>
      </div>
    </div>
  );
};

export default OriginDestinationFields;
