import dateConverter from '@/utils/dateConverter';
import getCurrentOrFutureDate from '@/utils/getCustomDate';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type TOriginDestination = {
  airport: string;
  city: string;
  country: string;
  code: string;
};

type TDepartureDate = {
  date: string;
  day: number;
  month: number;
  year: number;
  monthName: string;
  weekName: string;
};

type TCabinClass = {
  id: string;
  category: string;
  label: string;
  shortCode: string;
};

type TAirlines = {
  code: string;
  logo: string;
  name: string;
}[];

type TRoute = {
  origin: TOriginDestination;
  destination: TOriginDestination;
  departureDate: TDepartureDate;
};

type TPassenger = {
  adult: number;
  child: number;
  infant: number;
};

type TFilter = {
  directFlightOnly: boolean;
  cabinClass: TCabinClass;
  airline: TAirlines;
  flightType: string;
};

type TReturnState = {
  filter: TFilter;
  routes: TRoute[];
  passenger: TPassenger;
};

interface TSetReturnPayload {
  filter: TFilter;
  routes: TRoute[];
  passenger: TPassenger;
}

const convertingTwoDayLaterDate = dateConverter({
  ...getCurrentOrFutureDate(2),
});

const convertingFourDaysLaterDate = dateConverter({
  ...getCurrentOrFutureDate(6),
});

const initialState: TReturnState = {
  filter: {
    directFlightOnly: false,
    cabinClass: {
      id: 'c1',
      category: 'economy',
      label: 'Economy',
      shortCode: 'M',
    },
    airline: [],
    flightType: 'return',
  },
  routes: [
    {
      origin: {
        airport: 'Hazrat Shahjalal Intl Airport',
        city: 'Dhaka',
        country: 'Bangladesh',
        code: 'DAC',
      },
      destination: {
        airport: 'Coxs Bazar Airport',
        city: "Cox's Bazar",
        country: 'Bangladesh',
        code: 'CXB',
      },
      departureDate: {
        date: convertingTwoDayLaterDate?.date,
        day: convertingTwoDayLaterDate?.day,
        month: convertingTwoDayLaterDate?.month,
        year: convertingTwoDayLaterDate?.year,
        monthName: convertingTwoDayLaterDate?.monthName,
        weekName: convertingTwoDayLaterDate?.weekName,
      },
    },
    {
      origin: {
        airport: 'Coxs Bazar Airport',
        city: "Cox's Bazar",
        country: 'Bangladesh',
        code: 'CXB',
      },
      destination: {
        airport: 'Hazrat Shahjalal Intl Airport',
        city: 'Dhaka',
        country: 'Bangladesh',
        code: 'DAC',
      },
      departureDate: {
        date: convertingFourDaysLaterDate?.date,
        day: convertingFourDaysLaterDate?.day,
        month: convertingFourDaysLaterDate?.month,
        year: convertingFourDaysLaterDate?.year,
        monthName: convertingFourDaysLaterDate?.monthName,
        weekName: convertingFourDaysLaterDate?.weekName,
      },
    },
  ],
  passenger: {
    adult: 1,
    child: 0,
    infant: 0,
  },
};

export const returnSlice = createSlice({
  name: 'returnState',
  initialState,
  reducers: {
    resetReturnState: () => initialState,
    setReturn: (
      state: TReturnState,
      action: PayloadAction<TSetReturnPayload>,
    ) => {
      const { filter, routes, passenger } = action.payload;

      if (routes?.length > 1) {
        state.routes[0] = routes[0];
        state.routes[1] = routes[1];
      } else if (routes?.length === 1) {
        state.routes[0] = routes[0];
      }

      state.filter = { ...filter, flightType: 'return' };
      state.passenger = passenger;
    },
    swapReturnOriginAndDestination: (state) => {
      const tempOrigin = state.routes[0].origin;
      state.routes[0].origin = state.routes[0].destination;
      state.routes[0].destination = tempOrigin;
      state.routes[1].origin = state.routes[0].destination;
      state.routes[1].destination = state.routes[0].origin;
    },
    setReturnStateOrigin: (
      state,
      action: PayloadAction<TOriginDestination>,
    ) => {
      state.routes[0].origin = action.payload;
      state.routes[1].destination = action.payload;
    },
    setReturnStateDestination: (
      state,
      action: PayloadAction<TOriginDestination>,
    ) => {
      state.routes[0].destination = action.payload;
      state.routes[1].origin = action.payload;
    },
    setReturnStateDeparture: (state, action: PayloadAction<TDepartureDate>) => {
      state.routes[0].departureDate = action.payload;
    },
    setReturnStateReturn: (state, action: PayloadAction<TDepartureDate>) => {
      state.routes[1].departureDate = action.payload;
    },
    setReturnStateCabinClass: (state, action: PayloadAction<TCabinClass>) => {
      state.filter.cabinClass = action.payload;
    },
    setReturnStateAirlines: (state, action: PayloadAction<TAirlines>) => {
      state.filter.airline = action.payload;
    },
    setReturnStatePassenger: (state, action: PayloadAction<TPassenger>) => {
      state.passenger = action.payload;
    },
  },
});

export const {
  resetReturnState,
  setReturn,
  swapReturnOriginAndDestination,
  setReturnStateOrigin,
  setReturnStateDestination,
  setReturnStateDeparture,
  setReturnStateReturn,
  setReturnStateCabinClass,
  setReturnStateAirlines,
  setReturnStatePassenger,
} = returnSlice.actions;
export default returnSlice.reducer;
