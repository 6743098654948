export const cabinClass = [
  {
    id: 'c1',
    category: 'economy',
    label: 'Economy',
    shortCode: 'M',
  },
  {
    id: 'c2',
    category: 'economyPremium',
    label: 'Premium Economy',
    shortCode: 'W',
  },
  {
    id: 'c3',
    category: 'business',
    label: 'Business Class',
    shortCode: 'B',
  },
  {
    id: 'c4',
    category: 'firstClass',
    label: 'First Class',
    shortCode: 'F',
  },
];
