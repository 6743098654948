import axios from 'axios';
import { getCookie } from '@/utils/getCookie';

const useSecureFetcher = async (route: string, data: unknown) => {
  const jwt = getCookie('jwt');
  const userRole = getCookie('userRole');
  const apiLink = process.env.NEXT_PUBLIC_BASE_URL;
  try {
    if (jwt && userRole) {
      const response = await axios.post(`${apiLink}/${route}`, data, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
        withCredentials: true,
      });

      return response.data;
    } else {
      localStorage.removeItem('userInfo');
      document.cookie =
        'userInfo=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
      document.cookie =
        'userEmail=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
      document.cookie =
        'userCID=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
      document.cookie =
        'userRole=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
      document.cookie =
        'userPhone=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
      throw new Error();
    }
  } catch (error) {
    return { error, status: 'error' };
  }
};

export default useSecureFetcher;
