'use clint';

import { useRouter } from 'next/navigation';
import { useEffect, useState } from 'react';
import { toast } from 'sonner';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import {
  flightSearchFailed,
  flightSearchQuery,
  flightSearchStart,
} from '@/store/slices/flightSearchSlice';
import { setOneWay } from '@/store/slices/oneWaySlice';
import { setReturn } from '@/store/slices/returnSlice';
import useSecureFetcher from '@/hooks/useSecureFetcher';
import { IoSearch } from '@/Icons/io5';
import dateConverter from '@/utils/dateConverter';
import getCurrentOrFutureDate from '@/utils/getCustomDate';
import { CustomTCell, CustomTRow } from '../../custom/Table';

const HistoryTableRow = ({ history, allAirportsData }) => {
  const isLogged = useAppSelector((state) => state.userData.isLogged);

  // Update the boolean state based on the comparison
  const [isDateValid, setIsDateValid] = useState(true);

  //   constants
  const dispatch = useAppDispatch();
  const router = useRouter();

  const type =
    history?.routes?.length === 1
      ? 'One Way'
      : history?.routes?.length === 2
      ? 'Return'
      : history?.routes?.length > 2
      ? 'Multicity'
      : 'N/A';

  const [year, month, day] =
    history?.routes?.[0]?.departureDate?.split('-') ?? [];

  const dateObject = {
    day,
    month,
    year,
  };

  const convertingDate = dateConverter(dateObject);
  const convertingOneDayLaterDate = dateConverter({
    ...getCurrentOrFutureDate(),
  });

  // Create Date objects for comparison
  const historyDate = new Date(
    convertingDate.year,
    convertingDate.month - 1,
    convertingDate.day,
  );
  const futureDate = new Date(
    convertingOneDayLaterDate.year,
    convertingOneDayLaterDate.month - 1,
    convertingOneDayLaterDate.day,
  );

  // Compare the dates
  const isHistoryDateEarlier = historyDate < futureDate;

  useEffect(() => {
    setIsDateValid(isHistoryDateEarlier);
  }, [isHistoryDateEarlier]);

  // ===> handle search <===
  const HandleSearch = async (history: {
    routes: any[];
    passenger: any;
    filter: any;
  }) => {
    dispatch(flightSearchStart());
    //  setLPercent && setLPercent(20);

    const type =
      history?.routes?.length === 1
        ? 'oneway'
        : history?.routes?.length === 2
        ? 'return'
        : history?.routes?.length > 2 && 'multicity';

    const passenger = history?.passenger;
    const filter = {
      directFlightOnly: false,
      cabinClass: {
        id: 'c1',
        category: 'economy',
        label: 'Economy',
        shortCode: 'M',
      },
      airline: [],
      flightType: type,
    };
    const routes = history?.routes?.map(
      (route: { origin: string; destination: string }, index) => {
        const origin = allAirportsData?.find(
          (airport: { code: string }) =>
            airport?.code.toLowerCase().includes(route?.origin.toLowerCase()),
        );
        const destination = allAirportsData?.find(
          (airport: { code: string }) =>
            airport?.code
              .toLowerCase()
              .includes(route?.destination.toLowerCase()),
        );

        const convertingOneDayLaterDate = dateConverter({
          ...getCurrentOrFutureDate(2 * index),
        });

        return {
          origin: {
            airport: origin?.name,
            city: origin?.city,
            country: origin?.country?.name,
            code: origin?.code,
          },
          destination: {
            airport: destination?.name,
            city: destination?.city,
            country: destination?.country?.name,
            code: destination?.code,
          },
          departureDate: convertingOneDayLaterDate,
        };
      },
    );

    dispatch(setOneWay({ filter, routes, passenger }));
    dispatch(setReturn({ filter, routes, passenger }));

    const modifiedData = {
      filter: history?.filter,
      routes: history?.routes,
      passenger: history?.passenger,
    };

    try {
      if (!isLogged) {
        const response = await useSecureFetcher(
          `v2/searchNoUser2`,
          modifiedData,
        );

        if (response.status !== 'error') {
          dispatch(
            flightSearchQuery({
              data: response,
              details: { filter, routes, passenger },
            }),
          );
          router.push(`/flight-search?sid=${response._id}&type=${type}`);
        } else {
          dispatch(flightSearchFailed(response?.error));
          toast.error(`Error: ${response.message}`, {
            duration: 3000,
          });
        }
      } else {
        const response = await useSecureFetcher(`v2/search2`, modifiedData);

        if (response.status !== 'error') {
          dispatch(
            flightSearchQuery({
              data: response,
              details: { filter, routes, passenger },
            }),
          );
          router.push(`/flight-search?sid=${response._id}&type=${type}`);
        } else {
          dispatch(flightSearchFailed(response?.error));
          toast.error(`Error: ${response.error.message}`, {
            duration: 3000,
          });
        }
      }
    } catch (error: any) {
      dispatch(flightSearchFailed(error));
      toast(`Error: ${error?.message}`, {
        duration: 3000,
      });
    }
  };

  return (
    <CustomTRow className="font-medium">
      <CustomTCell>{history?.routes[0]?.origin || 'N/A'}</CustomTCell>
      <CustomTCell>
        {history?.routes[history?.routes?.length - 1]?.destination || 'N/A'}
      </CustomTCell>
      <CustomTCell>{type}</CustomTCell>
      <CustomTCell>{history?.routes[0]?.departureDate || 'N/A'}</CustomTCell>
      <CustomTCell>
        {history?.routes.length === 1
          ? 'N/A'
          : history?.routes[history?.routes?.length - 1]?.departureDate ||
            'N/A'}
      </CustomTCell>
      <CustomTCell>{history?.cabinClass || 'N/A'}</CustomTCell>
      <CustomTCell className="flex items-center gap-2 whitespace-nowrap">
        <span>
          {history?.passenger?.adult > 0
            ? `A-${history?.passenger?.adult}`
            : 'N/A'}
        </span>
        <span>
          {history?.passenger?.child > 0 && `C-${history?.passenger?.child}`}
        </span>
        <span>
          {history?.passenger?.infant > 0 && `I-${history?.passenger?.infant}`}
        </span>
      </CustomTCell>
      <CustomTCell>
        {!isDateValid ? (
          <button
            onClick={() => HandleSearch(history)}
            className="flex w-full items-center justify-center gap-1 rounded-md bg-primary_color px-3 py-[6px] text-xs text-white transition-colors duration-250 ease-in-out hover:bg-primary_color/85"
          >
            <IoSearch className="text-sm" /> Search
          </button>
        ) : (
          <button className="flex w-full cursor-not-allowed items-center justify-center gap-1 rounded-md bg-gray-400 px-3 py-[6px] text-xs font-medium text-white transition-colors duration-250 ease-in-out">
            <IoSearch className="text-sm" /> Search
          </button>
        )}
      </CustomTCell>
    </CustomTRow>
  );
};

export default HistoryTableRow;
