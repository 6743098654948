import axios from 'axios';
import { getCookie } from './getCookie';

const apiLink = process.env.NEXT_PUBLIC_LOCALHOST;

export const getAirlines = async (query: string) => {
  try {
    const jwt = getCookie('jwt');

    const response = await axios.post(
      `${apiLink}/aircontrol/byCodeAndName/${query}`,
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
        withCredentials: true,
      },
    );

    return response.data;
  } catch (error: any) {
    if (error.response) {
    } else if (error.request) {
    } else {
    }
    throw error;
  }
};
