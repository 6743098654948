import dateConverter from '@/utils/dateConverter';
import getCurrentOrFutureDate from '@/utils/getCustomDate';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type TOriginDestination = {
  airport: string;
  city: string;
  country: string;
  code: string;
};

type TDepartureDate = {
  date: string;
  day: number;
  month: number;
  year: number;
  monthName: string;
  weekName: string;
};

type TCabinClass = {
  id: string;
  category: string;
  label: string;
  shortCode: string;
};

type TAirlines = {
  code: string;
  logo: string;
  name: string;
}[];

type TRoute = {
  origin: TOriginDestination;
  destination: TOriginDestination;
  departureDate: TDepartureDate;
};

type TPassenger = {
  adult: number;
  child: number;
  infant: number;
};

type TFilter = {
  directFlightOnly: boolean;
  cabinClass: TCabinClass;
  airline: TAirlines;
  flightType: string;
};

type TMultiCityState = {
  filter: TFilter;
  routes: TRoute[];
  passenger: TPassenger;
};

interface TSetMultiCityPayload {
  filter: TFilter;
  routes: TRoute[];
  passenger: TPassenger;
}

const convertingTwoDayLaterDate = dateConverter({
  ...getCurrentOrFutureDate(2),
});

const convertingFourDaysLaterDate = dateConverter({
  ...getCurrentOrFutureDate(6),
});
const convertingSixDaysLaterDate = dateConverter({
  ...getCurrentOrFutureDate(10),
});
const convertingEightDaysLaterDate = dateConverter({
  ...getCurrentOrFutureDate(14),
});
const convertingTenDaysLaterDate = dateConverter({
  ...getCurrentOrFutureDate(18),
});

const extraRoute = [
  {
    origin: {
      airport: 'Indira Gandhi Intl Airport',
      city: 'New Delhi',
      country: 'India',
      code: 'DEL',
    },
    destination: {
      airport: 'Fresno Yosemite Intl Airport',
      city: 'Fresno',
      country: 'United States',
      code: 'FAT',
    },
    departureDate: {
      date: convertingSixDaysLaterDate?.date,
      day: convertingSixDaysLaterDate?.day,
      month: convertingSixDaysLaterDate?.month,
      year: convertingSixDaysLaterDate?.year,
      monthName: convertingSixDaysLaterDate?.monthName,
      weekName: convertingSixDaysLaterDate?.weekName,
    },
  },
  {
    origin: {
      airport: 'Fresno Yosemite Intl Airport',
      city: 'Fresno',
      country: 'United States',
      code: 'FAT',
    },
    destination: {
      airport: 'Chittagong Airport',
      city: 'Chittagong',
      country: 'Bangladesh',
      code: 'CGP',
    },
    departureDate: {
      date: convertingEightDaysLaterDate?.date,
      day: convertingEightDaysLaterDate?.day,
      month: convertingEightDaysLaterDate?.month,
      year: convertingEightDaysLaterDate?.year,
      monthName: convertingEightDaysLaterDate?.monthName,
      weekName: convertingEightDaysLaterDate?.weekName,
    },
  },
  {
    origin: {
      airport: 'Chittagong Airport',
      city: 'Chittagong',
      country: 'Bangladesh',
      code: 'CGP',
    },
    destination: {
      airport: 'Hazrat Shahjalal Intl Airport',
      city: 'Dhaka',
      country: 'Bangladesh',
      code: 'DAC',
    },
    departureDate: {
      date: convertingTenDaysLaterDate?.date,
      day: convertingTenDaysLaterDate?.day,
      month: convertingTenDaysLaterDate?.month,
      year: convertingTenDaysLaterDate?.year,
      monthName: convertingTenDaysLaterDate?.monthName,
      weekName: convertingTenDaysLaterDate?.weekName,
    },
  },
];

const initialState: TMultiCityState = {
  filter: {
    directFlightOnly: false,
    cabinClass: {
      id: 'c1',
      category: 'economy',
      label: 'Economy',
      shortCode: 'M',
    },
    airline: [],
    flightType: 'multicity',
  },
  routes: [
    {
      origin: {
        airport: 'Hazrat Shahjalal Intl Airport',
        city: 'Dhaka',
        country: 'Bangladesh',
        code: 'DAC',
      },
      destination: {
        airport: 'Coxs Bazar Airport',
        city: "Cox's Bazar",
        country: 'Bangladesh',
        code: 'CXB',
      },
      departureDate: {
        date: convertingTwoDayLaterDate?.date,
        day: convertingTwoDayLaterDate?.day,
        month: convertingTwoDayLaterDate?.month,
        year: convertingTwoDayLaterDate?.year,
        monthName: convertingTwoDayLaterDate?.monthName,
        weekName: convertingTwoDayLaterDate?.weekName,
      },
    },
    {
      origin: {
        airport: 'Coxs Bazar Airport',
        city: "Cox's Bazar",
        country: 'Bangladesh',
        code: 'CXB',
      },
      destination: {
        airport: 'Indira Gandhi Intl Airport',
        city: 'New Delhi',
        country: 'India',
        code: 'DEL',
      },
      departureDate: {
        date: convertingFourDaysLaterDate?.date,
        day: convertingFourDaysLaterDate?.day,
        month: convertingFourDaysLaterDate?.month,
        year: convertingFourDaysLaterDate?.year,
        monthName: convertingFourDaysLaterDate?.monthName,
        weekName: convertingFourDaysLaterDate?.weekName,
      },
    },
  ],
  passenger: {
    adult: 1,
    child: 0,
    infant: 0,
  },
};

export const multiCitySlice = createSlice({
  name: 'multiCitySlice',
  initialState,
  reducers: {
    resetMultiCityState: () => initialState,
    setMultiCity: (
      state: TMultiCityState,
      action: PayloadAction<TSetMultiCityPayload>,
    ) => {
      const { filter, routes, passenger } = action.payload;

      if (routes?.length > 2) {
        state.routes = routes;
      } else if (routes?.length === 2) {
        state.routes[0] = routes[0];
        state.routes[1] = routes[1];
      } else if (routes?.length === 1) {
        state.routes[0] = routes[0];
      }

      state.filter = { ...filter, flightType: 'multicity' };
      state.passenger = passenger;
    },

    setMultiCityStateOrigin: (
      state,
      action: PayloadAction<{ origin: TOriginDestination; routeNo: number }>,
    ) => {
      const { origin, routeNo } = action.payload;
      state.routes[routeNo].origin = origin;
    },
    setMultiCityStateDestination: (
      state,
      action: PayloadAction<{
        destination: TOriginDestination;
        routeNo: number;
      }>,
    ) => {
      const { destination, routeNo } = action.payload;
      state.routes[routeNo].destination = destination;
      if (state?.routes?.[routeNo + 1]?.origin) {
        state.routes[routeNo + 1].origin = destination;
      }
    },
    setMultiCityStateDeparture: (
      state,
      action: PayloadAction<{ departureDate: TDepartureDate; routeNo: number }>,
    ) => {
      const { departureDate, routeNo } = action.payload;
      state.routes[routeNo].departureDate = departureDate;
    },
    setMultiCityStateCabinClass: (
      state,
      action: PayloadAction<TCabinClass>,
    ) => {
      state.filter.cabinClass = action.payload;
    },
    setMultiCityStateAirlines: (state, action: PayloadAction<TAirlines>) => {
      state.filter.airline = action.payload;
    },
    setMultiCityStatePassenger: (state, action: PayloadAction<TPassenger>) => {
      state.passenger = action.payload;
    },
    swapMultiCityOriginAndDestination: (state, action) => {
      const routeNo = action.payload;
      const temp = state.routes[routeNo].origin;
      state.routes[routeNo].origin = state.routes[routeNo].destination;
      state.routes[routeNo].destination = temp;
    },
    addMultiCityRoute: (state) => {
      const routeLength = state.routes.length;
      if (routeLength === 2) {
        state.routes.push(extraRoute[0]);
      } else if (routeLength === 3) {
        state.routes.push(extraRoute[1]);
      } else if (routeLength === 4) {
        state.routes.push(extraRoute[2]);
      }
    },
    removeMultiCityRoute: (state, action: PayloadAction<number>) => {
      state.routes.splice(action.payload, 1);
    },
  },
});

export const {
  resetMultiCityState,
  setMultiCity,
  swapMultiCityOriginAndDestination,
  setMultiCityStateOrigin,
  setMultiCityStateDestination,
  setMultiCityStateDeparture,
  setMultiCityStateCabinClass,
  setMultiCityStateAirlines,
  setMultiCityStatePassenger,
  addMultiCityRoute,
  removeMultiCityRoute,
} = multiCitySlice.actions;
export default multiCitySlice.reducer;
