import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  searchQuery: null,
  searchResult: null,
  searchSortedResults: null,
  sortedPrice: 'invoice',
  searchDetails: null,
  searchPercent: 0,
  error: null,
  isError: false,
  isLoading: false,
  isSuccess: false,
  isClose: false,
};
const flightSearchSlice = createSlice({
  name: 'flightSearch',
  initialState,
  reducers: {
    flightSearchStart: (state) => {
      state.searchPercent = 10;
      state.searchQuery = null;
      state.searchResult = null;
      state.searchDetails = null;
      state.isLoading = true;
      state.isError = false;
      state.error = null;
      state.isSuccess = false;
      state.isClose = false;
    },
    flightSearchQuery: (state, action) => {
      const { data, details } = action.payload;
      state.searchPercent = 20;
      state.searchQuery = data;
      state.searchDetails = details;
    },
    flightSearchResult: (state, action) => {
      state.searchPercent = action.payload?.percent;
      state.searchResult = action.payload;
    },
    flightSearchEnd: (state) => {
      state.searchPercent = 100;
      state.isLoading = false;
      state.isSuccess = true;
      state.isError = false;
      state.error = null;
    },
    flightSearchClose: (state) => {
      state.searchPercent = 100;
      state.isLoading = false;
      state.isSuccess = true;
      state.isError = false;
      state.error = null;
      state.isClose = true;
    },
    flightSearchFailed: (state, action) => {
      state.searchPercent = 100;
      state.isError = true;
      // Convert Error object or error response to a serializable string
      state.error =
        action.payload instanceof Error
          ? action.payload
          : typeof action.payload === 'object'
          ? action.payload?.message || JSON.stringify(action.payload)
          : String(action.payload);
      state.isLoading = false;
      state.isSuccess = false;
    },
    flightSort: (state, action) => {
      state.searchSortedResults = action.payload;
    },
    flightPriceSort: (state, action) => {
      state.sortedPrice = action.payload;
    },
  },
});

export const {
  flightSearchStart,
  flightSearchQuery,
  flightSearchResult,
  flightSearchEnd,
  flightSearchClose,
  flightSearchFailed,
  flightSort,
  flightPriceSort,
} = flightSearchSlice.actions;

export default flightSearchSlice.reducer;
