import dateConverter from '@/utils/dateConverter';
import getCurrentOrFutureDate from '@/utils/getCustomDate';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type TOriginDestination = {
  airport: string;
  city: string;
  country: string;
  code: string;
};

type TDepartureDate = {
  date: string;
  day: number;
  month: number;
  year: number;
  monthName: string;
  weekName: string;
};

type TCabinClass = {
  id: string;
  category: string;
  label: string;
  shortCode: string;
};

type TAirlines = {
  code: string;
  logo: string;
  name: string;
}[];

type TRoute = {
  origin: TOriginDestination;
  destination: TOriginDestination;
  departureDate: TDepartureDate;
};

type TPassenger = {
  adult: number;
  child: number;
  infant: number;
};

type TFilter = {
  directFlightOnly: boolean;
  cabinClass: TCabinClass;
  airline: TAirlines;
  flightType: string;
};

type TOneWayState = {
  filter: TFilter;
  routes: TRoute[];
  passenger: TPassenger;
};

type TSetOneWayPayload = {
  filter: TFilter;
  routes: TRoute[];
  passenger: TPassenger;
};

const convertingOneDayLaterDate = dateConverter({
  ...getCurrentOrFutureDate(2),
});

const initialState: TOneWayState = {
  filter: {
    directFlightOnly: false,
    cabinClass: {
      id: 'c1',
      category: 'economy',
      label: 'Economy',
      shortCode: 'M',
    },
    airline: [],
    flightType: 'oneway',
  },
  routes: [
    {
      origin: {
        airport: 'Hazrat Shahjalal Intl Airport',
        city: 'Dhaka',
        country: 'Bangladesh',
        code: 'DAC',
      },
      destination: {
        airport: 'Coxs Bazar Airport',
        city: "Cox's Bazar",
        country: 'Bangladesh',
        code: 'CXB',
      },
      departureDate: {
        date: convertingOneDayLaterDate?.date,
        day: convertingOneDayLaterDate?.day,
        month: convertingOneDayLaterDate?.month,
        year: convertingOneDayLaterDate?.year,
        monthName: convertingOneDayLaterDate?.monthName,
        weekName: convertingOneDayLaterDate?.weekName,
      },
    },
  ],
  passenger: {
    adult: 1,
    child: 0,
    infant: 0,
  },
};

export const oneWaySlice = createSlice({
  name: 'oneWayState',
  initialState,
  reducers: {
    resetOnewayState: () => initialState,
    setOneWay: (
      state: TOneWayState,
      action: PayloadAction<TSetOneWayPayload>,
    ) => {
      const { filter, routes, passenger } = action.payload;

      if (routes?.length > 1) {
        state.routes[0] = routes[0];
      } else if (routes?.length === 1) {
        state.routes = routes;
      }

      state.filter = { ...filter, flightType: 'oneway' };
      state.passenger = passenger;
    },
    swapOnewayOriginAndDestination: (state) => {
      const temp = state.routes[0].origin;
      state.routes[0].origin = state.routes[0].destination;
      state.routes[0].destination = temp;
    },
    setOnewayStateOrigin: (
      state: TOneWayState,
      action: PayloadAction<TOriginDestination>,
    ) => {
      state.routes[0].origin = action.payload;
    },
    setOnewayStateDestination: (
      state: TOneWayState,
      action: PayloadAction<TOriginDestination>,
    ) => {
      state.routes[0].destination = action.payload;
    },

    setOnewayStateDeparture: (
      state: TOneWayState,
      action: PayloadAction<TDepartureDate>,
    ) => {
      state.routes[0].departureDate = action.payload;
    },
    setOnewayStateCabinClass: (
      state: TOneWayState,
      action: PayloadAction<TCabinClass>,
    ) => {
      state.filter.cabinClass = action.payload;
    },
    setOnewayStateAirlines: (
      state: TOneWayState,
      action: PayloadAction<TAirlines>,
    ) => {
      state.filter.airline = action.payload;
    },
    setOnewayStatePassenger: (
      state: TOneWayState,
      action: PayloadAction<TPassenger>,
    ) => {
      state.passenger = action.payload;
    },
  },
});

export const {
  resetOnewayState,
  setOneWay,
  swapOnewayOriginAndDestination,
  setOnewayStateOrigin,
  setOnewayStateDestination,
  setOnewayStateDeparture,
  setOnewayStateCabinClass,
  setOnewayStateAirlines,
  setOnewayStatePassenger,
} = oneWaySlice.actions;
export default oneWaySlice.reducer;
