import { useMemo } from 'react';

const usePagination = (data: any[], pageLimit: number, currentPage: number) => {
  // Calculate total number of pages and total items
  const totalItems = data?.length;
  if (totalItems > 0) {
    const totalPages = Math.ceil(totalItems / pageLimit);

    // Calculate current items and start/end indices
    const { currentItems, startIndex, endIndex } = useMemo(() => {
      const startIndex = (currentPage - 1) * pageLimit;
      const endIndex = Math.min(startIndex + pageLimit, totalItems); // Ensure endIndex does not exceed total items
      const currentItems = data && data?.slice(startIndex, endIndex);

      return { currentItems, startIndex: startIndex + 1, endIndex };
    }, [currentPage, pageLimit, totalItems, data]);

    return { currentItems, totalPages, startIndex, endIndex, totalItems };
  } else {
    return {
      currentItems: [],
      totalPages: 0,
      startIndex: 0,
      endIndex: 0,
      totalItems: 0,
    };
  }
};

export default usePagination;
